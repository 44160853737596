import { App } from 'src/components/App'
import LoginView from 'src/views/SignIn'

const Login = (props) => (
  <App.Layout userStore={props.userStore}>
    <LoginView {...props} />
  </App.Layout>
)

export default Login
