import { App } from 'src/components/App'
import { storage } from 'src/storage'
import { SignIn } from './SignIn'

const SignInView = () => {
  const redirect = storage.session.getItem('returnUrl') || undefined

  return (
    <App.RedirectIf.IsNotGuest redirect={redirect} paywall>
      <App.DispatchPageView>
        <SignIn />
      </App.DispatchPageView>
    </App.RedirectIf.IsNotGuest>
  )
}

export default SignInView
